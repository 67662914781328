import { Rem } from "@/types/common";
import { strokeWidth as strokeWidthEnum } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { LucideIcon } from "lucide-react";
import dynamic from "next/dynamic";
import { useState } from "react";
import CircularButton, { CircularButtonProps } from "../CircularButton";

const LoadingAnimation = dynamic(() => import("@/components/LoadingAnimation"), { ssr: false });

export type CircularOutlineIconProps = {
    radius?: Rem;
    buttonColor?: string;
    borderColor?: string;
    size: number;
    fill?: string;
    color?: string;
    hoverColor?: string;
    hoverBackgroundColor?: string;
    fillWhenActive?: boolean;
    isLoading?: boolean;
    isActive?: boolean;
    activeColor?: string;
    Icon: LucideIcon;
    strokeWidth?: number;
} & CircularButtonProps;

const CircularOutlineIcon = ({
    ref,
    radius = "4rem",
    disabled,
    buttonColor = disabled ? "rgb(146, 146, 146, 0.3)" : "transparent",
    borderColor,
    color = themeColors.neutralBlack,
    hoverColor,
    isLoading = false,
    fill,
    fillWhenActive,
    isActive,
    sx,
    activeColor = themeColors.primary,
    size,
    onClick,
    Icon,
    strokeWidth = strokeWidthEnum.normal,
    hoverBackgroundColor,
    ...btnProps
}: CircularOutlineIconProps & {
    ref?: React.RefObject<unknown>;
}) => {
    const [isHover, setIsHover] = useState(false);

    const isHoverable = !disabled;

    return (
        <CircularButton
            ref={ref}
            radius={radius}
            onMouseEnter={() => setIsHover(isHoverable)}
            onMouseLeave={() => setIsHover(false)}
            onClick={e => {
                if (disabled) return;
                e.stopPropagation();
                e.preventDefault();
                onClick?.(e);
                setIsHover(false);
            }}
            sx={{
                "&:hover": {
                    outline: `1px solid ${themeColors.neutralBlack}`,
                    backgroundColor: hoverBackgroundColor || buttonColor,
                },
                "&:active": {
                    transform: "scale(0.95)",
                    outline: "none",
                },
                border: "solid",
                borderRadius: 999,
                borderWidth: buttonColor === "transparent" || borderColor ? "1px" : 0,
                borderColor: borderColor ?? themeColors.neutral1,
                backgroundColor: buttonColor,
                ...sx,
            }}
            {...btnProps}>
            {isLoading ? (
                <LoadingAnimation size={24} />
            ) : (
                <Icon
                    size={size}
                    strokeWidth={strokeWidth ?? strokeWidthEnum.normal}
                    fill={isActive && fillWhenActive ? fill : "transparent"}
                    color={isActive ? (fill ?? activeColor) : isHover ? hoverColor : color}
                />
            )}
        </CircularButton>
    );
};

CircularOutlineIcon.displayName = "CircularHoverIcon";
export default CircularOutlineIcon;
