import { useCallback, useEffect } from "react";
import useInterval from "react-use/lib/useInterval";

export const useAutoSave = ({
    isDirty,
    save: _save,
    interval,
    block = false,
    warningText = "You have unsaved changes - are you sure you wish to leave this page?",
}: {
    isDirty: () => boolean;
    save: () => Promise<void> | void;
    interval: number;
    block?: boolean;
    warningText?: string;
}) => {
    const save = useCallback(async () => {
        if (isDirty()) {
            await _save();
        }
    }, [isDirty, _save]);

    useInterval(save, interval);

    useEffect(() => {
        // Save on window blur
        window.addEventListener("visibilitychange", save);

        return () => {
            window.removeEventListener("visibilitychange", save);
        };
    }, [save, isDirty]);

    useEffect(() => {
        const handleWindowClose = (event?: BeforeUnloadEvent) => {
            if (!isDirty()) return;
            save();

            if (event) {
                // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
                return (event.returnValue = warningText);
            }
        };

        if (block) {
            window.addEventListener("beforeunload", handleWindowClose);
            window.addEventListener("popstate", handleWindowClose);
        }

        return () => {
            if (block) {
                window.removeEventListener("beforeunload", handleWindowClose);
                window.removeEventListener("popstate", handleWindowClose);
            }
        };
    }, [block, isDirty, warningText]);
};
