import { useAuthPopupContextSelector, OpenAuthPopupProps } from "@/features/Auth/AuthPopup/hook/useAuthPopupContext";
import { AuthEvent } from "@/features/Auth/AuthPopup/utils/constants";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import noop from "@knowt/syncing/utils/noop";
import { useCallback } from "react";

type popupPropsType = Omit<OpenAuthPopupProps, "event">;

type CheckAuthProps = {
    event: AuthEvent;
    popupProps?: popupPropsType;
    onAuthSuccess?: () => void;
    onAuthFail?: () => void;
    timeoutDelay?: number;
};

export const useCheckAuth = () => {
    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);
    const { loginInProgress, user } = useCurrentUser();

    const checkAuth = useCallback(
        async ({
            event,
            popupProps,
            timeoutDelay = 1000,
            onAuthSuccess = noop,
            onAuthFail = noop,
        }: CheckAuthProps): Promise<boolean> => {
            if (loginInProgress) {
                return new Promise<boolean>(resolve => {
                    setTimeout(async () => {
                        const result = await checkAuth({
                            event,
                            popupProps,
                            onAuthSuccess,
                            onAuthFail,
                            timeoutDelay,
                        });
                        resolve(result);
                    }, timeoutDelay);
                });
            } else if (!user) {
                onAuthFail();
                openAuthPopup({ event, ...popupProps });
                return false;
            }

            onAuthSuccess();
            return true;
        },
        [loginInProgress, openAuthPopup, user]
    );

    return { checkAuth };
};
