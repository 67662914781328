import { isDevelopment } from "@/config/deployConstants";
import Cookies from "js-cookie";
import { __DEV__ } from "@/lib/constants";
import { DynamicKeyValueStore } from "@knowt/syncing/hooks/swr/localStorage";
const COOKIE_ATTRIBUTES = {
    path: "/",
    domain: __DEV__ ? "localhost" : isDevelopment ? "test.knowt.com" : "knowt.com",
};

const COOKIE_ATTRIBUTES_NO_DOMAIN = {
    path: "/",
    domain: "localhost",
};

export const dynamicKeyValueStore: DynamicKeyValueStore = {
    get: (key: string) => {
        if (typeof window === "undefined") return "";
        return localStorage.getItem(key);
    },
    // mainly for async
    getOnlyUseCookies: async (key: string) => {
        if (typeof window === "undefined") {
            const cookies = require("next/headers").cookies;
            try {
                return (await cookies())?.get(key)?.value || "";
            } catch {
                return "";
            }
        }

        return localStorage.getItem(key);
    },
    find: (prefix: string) => {
        if (typeof window === "undefined") {
            const cookies = require("next/headers").cookies;
            return Object.fromEntries(cookies()?.entries() ?? []).filter(([key]) => key.startsWith(prefix));
        }

        return Object.fromEntries(Object.entries(localStorage).filter(([key]) => key.startsWith(prefix)));
    },
    set: (key: string, value: string | number, expires = 365) => {
        Cookies.set(key, value.toString(), {
            expires,
        });
        localStorage.setItem(key, value.toString());
    },
    remove: (key: string) => {
        Cookies.remove(key);
        Cookies.remove(key, COOKIE_ATTRIBUTES_NO_DOMAIN);
        Cookies.remove(key, COOKIE_ATTRIBUTES);
        localStorage.removeItem(key);
    },
    removeAll: () => {
        for (const cookieName of Object.keys(Cookies.get())) {
            Cookies.remove(cookieName);
            Cookies.remove(cookieName, COOKIE_ATTRIBUTES_NO_DOMAIN);
            Cookies.remove(cookieName, COOKIE_ATTRIBUTES);
        }

        for (const key of Object.keys(localStorage)) {
            localStorage.removeItem(key);
        }
    },
};
