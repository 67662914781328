import { ASSETS_URL } from "@/config/deployConstants";

// TODO: change this when we get text per each upgrade event
export type AuthEventProps = {
    quote: string;
    quoteWriter: string;
    image: string;
};

export enum AuthEvent {
    // Only used when clicking login/signup button in navbar
    GENERAL_SIGNUP = "GENERAL_SIGNUP",

    // General
    BOOKMARK_ITEM = "BOOKMARK_ITEM",
    RATE_ITEM = "RATE_ITEM",
    PROMO_BANNER = "PROMO_BANNER",
    SEARCH_MY_FILES = "SEARCH_MY_FILES",

    // Landing Pages
    LANDING_FLASHCARDS = "LANDING_FLASHCARDS",

    // Upload Landing Pages
    LANDING_IMPORT_QUIZLET = "LANDING_IMPORT_QUIZLET",
    LANDING_UPLOAD_PDF = "LANDING_UPLOAD_PDF",
    LANDING_UPLOAD_PPT = "LANDING_UPLOAD_PPT",
    LANDING_UPLOAD_VIDEO = "LANDING_UPLOAD_VIDEO",
    LANDING_UPLOAD_NOTE = "LANDING_UPLOAD_NOTE",
    LANDING_TEACHER_TOOLS = "LANDING_TEACHER_TOOLS",
    FAVORITE_AI_TOOL = "FAVORITE_AI_TOOL",

    // Chrome Help Page (if the user installed the chrome extension without being on knowt somehow)
    CHROME_IMPORT_POPUP = "CHROME_IMPORT_POPUP",

    // Flashcard
    FLASHCARD_CAROUSEL = "FLASHCARD_CAROUSEL",
    FLASHCARD_LIST_CONVERTER = "FLASHCARD_LIST_FLOATING",
    STAR_FLASHCARD = "STAR_FLASHCARD",
    COMBINE_FLASHCARDS = "COMBINE_FLASHCARDS",
    PLAY_AUDIO = "PLAY_AUDIO",
    CREATE_FLASHCARDS = "CREATE_FLASHCARDS",
    DUPLICATE_FLASHCARDS = "DUPLICATE_FLASHCARDS",
    IMPORT_FLASHCARDS = "IMPORT_FLASHCARDS",
    EDITING_SESSION_EXPIRED = "EDITING_SESSION_EXPIRED",

    // Flashcard Study Modes
    SHUFFLE_FLASHCARDS = "SHUFFLE_FLASHCARDS",
    OPEN_STUDY_SETTINGS = "OPEN_STUDY_SETTINGS",
    END_CAROUSEL_STUDY_FLASHCARDS_LEARN_MODE = "END_CAROUSEL_STUDY_FLASHCARDS_LEARN_MODE",
    END_CAROUSEL_STUDY_FLASHCARDS_SPACED_MODE = "END_CAROUSEL_STUDY_FLASHCARDS_SPACED_MODE",
    STUDY_FLASHCARDS_LEARN_MODE = "STUDY_FLASHCARDS_LEARN_MODE",
    STUDY_FLASHCARDS_SPACED_MODE = "STUDY_FLASHCARDS_SPACED_MODE",
    STUDY_FLASHCARDS_TEST_MODE = "STUDY_FLASHCARDS_TEST_MODE",
    STUDY_FLASHCARDS_REVIEW_MODE = "STUDY_FLASHCARDS_REVIEW_MODE",
    STUDY_FLASHCARDS_MATCH_MODE = "STUDY_FLASHCARDS_MATCH_MODE",

    // Note
    NOTE_EDITOR_FLOATING = "NOTE_EDITOR_FLOATING",
    NOTE_EDITOR_STUDY_BUTTON = "NOTE_EDITOR_STUDY_BUTTON",
    NOTE_EDITOR_ADD_BUTTON = "NOTE_EDITOR_ADD_BUTTON",
    DUPLICATE_NOTE = "DUPLICATE_NOTE",

    // Media
    CHAT_WITH_KAI = "CHAT_WITH_KAI",
    DUPLICATE_MEDIA = "DUPLICATE_MEDIA",

    // Plans
    CHOOSE_PLAN = "CHOOSE_PLAN",
    UNIDAYS_PROMO = "UNIDAYS_PROMO",
    STUDENT_BEANS_PROMO = "STUDENT_BEANS_PROMO",
    TEACHER_REFER_SCHOOL = "TEACHER_REFER_SCHOOL",

    // Profile
    FOLLOW_USER = "FOLLOW_USER",
    SEE_FOLLOWERS_FOLLOWING = "SEE_FOLLOWERS_FOLLOWING",

    // Exams
    EXAM_LEARN_MODE = "EXAM_LEARN_MODE",
    EXAM_TEST_MODE = "EXAM_TEST_MODE",

    // Classes
    JOIN_CLASS = "JOIN_CLASS",
    CREATE_CLASS = "CREATE_CLASS",
    AI_TOOLS = "AI_TOOLS",
    ADD_CLASS_FILE = "ADD_CLASS_FILE",

    // Exam Room
    MCQ_ROOM_FLOATING = "MCQ_ROOM_FLOATING",
    FRQ_ROOM_FLOATING = "FRQ_ROOM_FLOATING",
}

const AUTH_EVENT_QUOTES: { [key in AuthEvent]?: AuthEventProps } = {
    GENERAL_SIGNUP: {
        quote: "Knowt’s quiz and spaced repetition features have been a lifesaver. I’m going to Columbia now and studying with Knowt helped me get there! ",
        quoteWriter: "Victoria Buendia-Serrano, College freshman",
        image: `${ASSETS_URL}/images/Upgrade_Popup/upgarde_popup.png`,
    },
    LANDING_UPLOAD_PDF: {
        quote: "Knowt has been an absolute life-saver for me - no other revision methods worked for me. There's so many so it's perfect for any learning or revision type. It’s also incredible considering Knowt is free! ",
        quoteWriter: "Milan Gudka, 11th Grade",
        image: `${ASSETS_URL}/images/Upgrade_Popup/upgrade-pdf-screenshot.svg`,
    },
    LANDING_UPLOAD_PPT: {
        quote: "Knowt has helped me organize my notes easier and find a place to share them. I love using the AI to speed up my note taking process and how much customizability it has.",
        quoteWriter: "Eitan Campbell, 12th Grade",
        image: `${ASSETS_URL}/images/Upgrade_Popup/upgrade-pdf-screenshot.svg`,
    },
    LANDING_UPLOAD_VIDEO: {
        quote: "I loved the AI for flashcard writing, it saves so much time! The spaced repetition can really come in clutch for last minute studying and helps you remember everything!",
        quoteWriter: "Shamika Agrawal, 12th Grade",
        image: `${ASSETS_URL}/images/Upgrade_Popup/upgrade-video-screenshot.svg`,
    },
    LANDING_UPLOAD_NOTE: {
        quote: "Knowt has been a game changer for me when it comes to studying smarter, not harder, and I love the fact that you have integrated tools for various subjects and have AI to help.",
        quoteWriter: "Jan Ordonez, 12th Grade",
        image: `${ASSETS_URL}/images/Upgrade_Popup/upgrade-note-screenshot.svg`,
    },
    LANDING_TEACHER_TOOLS: {
        quote: "Knowt has been a game changer for me when it comes to studying smarter, not harder, and I love the fact that you have integrated tools for various subjects and have AI to help.",
        quoteWriter: "Jan Ordonez, 12th Grade",
        image: `${ASSETS_URL}/images/Upgrade_Popup/upgrade-note-screenshot.svg`,
    },
};

export default AUTH_EVENT_QUOTES;
